import TinyMCE from "@components/Editor";
import { useToast } from "@components/toast/ToastProvider";
import store from "@redux/store";
import { validateNoSpaces, validateSlug } from "@utils/common";
import { Form, Input, Tabs, UploadFile } from "antd";
import "jodit";
import React, { useEffect, useRef, useState } from "react";

interface IProps {
    introduceVi: string;
    introduceEn: string;
    introduceCn: string;

    setIntroduceVi: (value: string) => void;
    setIntroduceEn: (value: string) => void;
    setIntroduceCn: (value: string) => void;

    form: any;
    errorContent?: string;
    setErrorContent?: (value: string) => void;

    fieldName: string;
}

const Tab: React.FunctionComponent<IProps> = (props: IProps) => {
    const {
        introduceVi,
        introduceEn,
        introduceCn,
        setIntroduceVi,
        setIntroduceEn,
        setIntroduceCn,
        form,
        errorContent,
        setErrorContent,
        fieldName,
    } = props;

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const token = store.getState().auth.accessToken;
    const type = store.getState().auth.type;
    const { showToast } = useToast();

    const initialItems = [] as any[];

    const [activeKey, setActiveKey] = useState("titleVi");
    const [items, setItems] = useState(initialItems);

    useEffect(() => {
        if (errorContent === "titleVi" || errorContent === "titleEn" || errorContent === "titleCn") {
            setActiveKey(errorContent);
        }
        if (errorContent === "introduceVi") {
            setActiveKey("titleVi");
            form.setFields([
                {
                    name: "introduceVi",
                    errors: ["Thông tin bắt buộc"],
                },
            ]);
        }
        if (errorContent === "introduceEn") {
            setActiveKey("titleEn");
            form.setFields([
                {
                    name: "introduceEn",
                    errors: ["Field required"],
                },
            ]);
        }
        if (errorContent === "introduceCn") {
            setActiveKey("titleCn");
            form.setFields([
                {
                    name: "introduceCn",
                    errors: ["必填字段"],
                },
            ]);
        }
        setErrorContent && setErrorContent("");
    }, [errorContent]);

    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey);
    };

    const newTabIndex = useRef(0);

    const add = () => {
        const newActiveKey = `newTab${newTabIndex.current++}`;
        const newPanes = [...items];
        newPanes.push({ label: "New Tab", children: <>Content of new Tab</>, key: newActiveKey, closable: true });
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const remove = (targetKey: any) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        items.forEach((item, i) => {
            if (item.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = items.filter((item) => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: "add" | "remove") => {
        if (action === "add") {
            add();
        } else {
            remove(targetKey);
        }
    };

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Tabs
                    type="editable-card"
                    onChange={onChange}
                    activeKey={activeKey}
                    onEdit={onEdit}
                    items={[
                        {
                            label: "Tiếng Việt",
                            children: (
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[fieldName, "titleVi"]}
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin bắt buộc",
                                                    },
                                                    {
                                                        validator: (rule, value, callback) =>
                                                            validateNoSpaces(rule, value, callback, "vi"),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Tiêu đề</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[fieldName, "shortContentVi"]}
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin bắt buộc",
                                                    },
                                                    {
                                                        validator: (rule, value, callback) =>
                                                            validateNoSpaces(rule, value, callback, "vi"),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Mô tả ngắn</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[fieldName, "slugVi"]}
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        validator: (rule, value, callback) =>
                                                            validateNoSpaces(rule, value, callback, "vi"),
                                                    },
                                                    {
                                                        validator: (rule, value, callback) => {
                                                            validateSlug(rule, value, callback, "vi");
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Slug</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[fieldName, "introduceVi"]}
                                                className="form-floating"
                                            >
                                                {/* <ReactQuill
                                                    className="text-editor"
                                                    value={introduceVi}
                                                    onChange={(value: any) => {
                                                        setIntroduceVi(value);
                                                    }}
                                                    formats={formats}
                                                    modules={modules}
                                                /> */}
                                                <TinyMCE
                                                    value={introduceVi}
                                                    onChange={(value: string) => setIntroduceVi(value)}
                                                />
                                            </Form.Item>
                                            <label>Nội dung</label>
                                        </div>
                                    </div>
                                </div>
                            ),
                            key: "titleVi",
                            closable: false,
                        },
                        {
                            label: "English",
                            children: (
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[fieldName, "titleEn"]}
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Field required",
                                                    },
                                                    {
                                                        validator: (rule, value, callback) =>
                                                            validateNoSpaces(rule, value, callback, "en"),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Title</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[fieldName, "shortContentEn"]}
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Field required",
                                                    },
                                                    {
                                                        validator: (rule, value, callback) =>
                                                            validateNoSpaces(rule, value, callback, "en"),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Short Content</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[fieldName, "slugEn"]}
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        validator: (rule, value, callback) =>
                                                            validateNoSpaces(rule, value, callback, "en"),
                                                    },
                                                    {
                                                        validator: (rule, value, callback) => {
                                                            validateSlug(rule, value, callback, "en");
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Slug</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[fieldName, "introduceEn"]}
                                                className="form-floating"
                                            >
                                                {/* <ReactQuill
                                                    className="text-editor"
                                                    value={introduceEn}
                                                    onChange={(value: any) => {
                                                        setIntroduceEn(value);
                                                    }}
                                                    formats={formats}
                                                    modules={modules}
                                                /> */}
                                                <TinyMCE
                                                    value={introduceEn}
                                                    onChange={(value: string) => setIntroduceEn(value)}
                                                />
                                            </Form.Item>
                                            <label>Content</label>
                                        </div>
                                    </div>
                                </div>
                            ),
                            key: "titleEn",
                            closable: false,
                        },
                        {
                            label: "中国人",
                            children: (
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[fieldName, "titleCn"]}
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "必填字段",
                                                    },
                                                    {
                                                        validator: (rule, value, callback) =>
                                                            validateNoSpaces(rule, value, callback, "cn"),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>标题</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[fieldName, "shortContentCn"]}
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "必填字段",
                                                    },
                                                    {
                                                        validator: (rule, value, callback) =>
                                                            validateNoSpaces(rule, value, callback, "cn"),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>简短的内容</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[fieldName, "slugCn"]}
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        validator: (rule, value, callback) =>
                                                            validateNoSpaces(rule, value, callback, "cn"),
                                                    },
                                                    {
                                                        validator: (rule, value, callback) => {
                                                            validateSlug(rule, value, callback, "cn");
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Slug</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[fieldName, "introduceCn"]}
                                                className="form-floating"
                                            >
                                                {/* <ReactQuill
                                                    className="text-editor"
                                                    value={introduceCn}
                                                    onChange={(value: any) => {
                                                        setIntroduceCn(value);
                                                    }}
                                                    formats={formats}
                                                    modules={modules}
                                                /> */}
                                                <TinyMCE
                                                    value={introduceCn}
                                                    onChange={(value: string) => setIntroduceCn(value)}
                                                />
                                            </Form.Item>
                                            <label>内容</label>
                                        </div>
                                    </div>
                                </div>
                            ),
                            key: "titleCn",
                            closable: false,
                        },
                    ]}
                />
            </div>
        </>
    );
};

export default Tab;
