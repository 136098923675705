import store from "@redux/store";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";

interface IProps {
    value: string;
    onChange: (value: string) => void;
}

export default function TinyMCE(props: IProps) {
    const [editValue, setEditValue] = useState(props.value);
    const token = store.getState().auth.accessToken;
    const type = store.getState().auth.type;
    const handleImageUpload = (blobInfo: any, progress: any, failure: any) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", `${process.env.REACT_APP_HOST}/api/file/upload/original`, true);
            xhr.setRequestHeader("Authorization", `${type} ${token}`);

            const formData = new FormData();
            formData.append("file", blobInfo.blob(), blobInfo.filename());

            xhr.upload.onprogress = (e) => {
                progress((e.loaded / e.total) * 100);
                if (progress && typeof progress === "function") {
                    const percent = 0;
                    progress(percent);
                }
            };

            xhr.onload = () => {
                if (xhr.status === 403) {
                    reject({ message: "HTTP Error: " + xhr.status, remove: true });
                    return;
                }

                if (xhr.status < 200 || xhr.status >= 300) {
                    reject("HTTP Error: " + xhr.status);
                    return;
                }

                const json = JSON.parse(xhr.responseText);
                console.log(json.url);

                if (!json || typeof json.url != "string") {
                    reject("Invalid JSON: " + xhr.responseText);
                    return;
                }

                resolve(`${process.env.REACT_APP_CDN}assets/${json.url}`);
            };

            xhr.onerror = () => {
                reject({ message: "Image upload failed", remove: true });
                if (failure && typeof failure === "function") {
                    failure("Image upload failed");
                }
            };

            xhr.send(formData);
        });
    };

    return (
        <>
            <Editor
                apiKey="q4ok06gj0zbpzdfshzp5msl0y7hys9qyv2na7zqbwzgt0f33"
                initialValue={props.value}
                // value={props.value}
                onEditorChange={(a, editor) => {
                    setEditValue(a);
                }}
                onBlur={() => props?.onChange(editValue)}
                init={{
                    body_class: "text-editor",
                    height: 500,
                    plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                    ],
                    toolbar:
                        "undo redo | styles | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                    images_upload_url: `${process.env.REACT_APP_HOST}/api/file/upload/original`,
                    automatic_uploads: true,
                    images_reuse_filename: true,
                    images_upload_handler: handleImageUpload as any,
                }}
            />
        </>
    );
}
