import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination } from "@components/pagination";
import { schoolLogo } from "@consts";
import {
    useDeleteMultiSchoolMutation,
    useDeleteSchoolMutation,
    useGetListSchoolPaginateQuery,
    useGetSchoolDetailByIdQuery,
    useLazyGetSchoolDetailByIdQuery,
} from "@redux/queries/admin/admin.school";
import { useGetListSchoolTypeQuery } from "@redux/queries/admin/admin.school.type";
import { history } from "@utils/helper-router";
import { Button, Descriptions, Drawer, Empty, Modal, Space, Table, Typography } from "antd";
import Title from "antd/es/typography/Title";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import FilterSchool from "./components/FilterSchool";
import { SchoolAttributeLabels } from "./schoolAttribute.enum";
import { Link } from "react-router-dom";
import useSessionStorageState from "use-session-storage-state";

const { Text } = Typography;

const School: React.FunctionComponent = () => {
    const [selectedSchool, setSelectedSchool] = useState<IAdminSchool | null>(null);
    const [detailId, setDetailID] = useState<string | null>(null);
    const [modal, contextHolder] = Modal.useModal();

    const {
        data: list_school_type,
        isLoading: isLoadingSchoolType,
        error: errorSchoolType,
    } = useGetListSchoolTypeQuery();

    const [query, setQuery] = useSessionStorageState("school_query", {
        defaultValue: {
            page: 1,
            limit: 10,
            sortBy: "createdAt",
        } as any
    });
    const { data, isLoading, isFetching } = useGetListSchoolPaginateQuery(query as any);

    const [filter, setFilter] = useState<any>({});

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                // page: 1,
            },
            _.identity
        );
        setQuery(newQuery as any);
    }, [filter]);

    const [sortCreatedAt, setSortCreatedAt] = useState<"descend" | undefined>("descend");
    const [sortLikeTotal, setSortLikeTotal] = useState<"descend" | undefined>(undefined);
    const [sortView, setSortView] = useState<"descend" | undefined>(undefined);
    const [sortRank, setSortRank] = useState<"descend" | undefined>(undefined);
    const [sortCode, setSortCode] = useState<"descend" | undefined>(undefined);
    // const { data: detail } = useGetSchoolDetailByIdQuery({
    //     id: detailId || "",
    // });
    const [getSchoolDetailById, { data: detail }] = useLazyGetSchoolDetailByIdQuery();

    useEffect(() => {
        if (detailId) {
            getSchoolDetailById({
                id: detailId,
            });
        }
    }, [detailId]);

    const [openDrawer, setOpenDrawer] = useState(false);

    const [deleteSchool, isLoadingDelete] = useDeleteSchoolMutation();
    const [deleteMultiSchool, isLoadingMultiDelete] = useDeleteMultiSchoolMutation();

    const handleDelete = async (id: string) => {
        const result = await deleteSchool({
            id: id,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleMultiDelete = async () => {
        const result = await deleteMultiSchool({
            ids: selectedRowKeys as any,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleAddNew = () => {};

    const handleViewDetail = (id: string) => {
        setDetailID(id);
        setOpenDrawer(true);
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: <div className="text-title-table">Mã trường</div>,
            dataIndex: "code",
            key: "code",
            sorter: true,
            sortOrder: sortCode,
            sortDirections: ["descend"] as any,
            onHeaderCell: () => ({
                onClick: () => {
                    if (sortCode === "descend") {
                        setSortCode(undefined);
                        setQuery({ ...query, sortBy: undefined });
                    }
                    if (sortCode === undefined) {
                        setSortCode("descend");
                        setQuery({ ...query, sortBy: "code" });
                        setSortLikeTotal(undefined);
                        setSortView(undefined);
                        setSortCreatedAt(undefined);
                    }
                },
            }),
        },
        {
            title: <div className="text-title-table">Logo</div>,
            dataIndex: "logo",
            key: "logo",
            render: (logo: string) => {
                return (
                    <img
                        src={logo ? process.env.REACT_APP_CDN + logo : schoolLogo}
                        alt="logo"
                        height={50}
                    />
                );
            },
        },
        {
            title: <div className="text-title-table">Tên trường</div>,
            dataIndex: "language",
            key: "titleVn",
            render: (language: any, record: any) => {
                const slug = _.get(record, "language[0].slug", "");
                return (
                    <Link
                        to={`https://studyinchina.io/${slug}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {language[0]?.name}
                    </Link>
                );
            },
        },
        {
            title: <div className="text-title-table">Tên tiếng Anh</div>,
            dataIndex: "language",
            key: "titleEn",
            render: (language: any) => {
                return language[1]?.name;
            },
        },
        {
            title: <div className="text-title-table">Tên tiếng Trung</div>,
            dataIndex: "language",
            key: "titleCn",
            render: (language: any) => {
                return language[2]?.name;
            },
        },
        {
            title: <div className="text-title-table">Rank</div>,
            dataIndex: "rank",
            key: "rank",
            sorter: true,
            sortOrder: sortRank,
            sortDirections: ["descend"] as any,
            onHeaderCell: () => ({
                onClick: () => {
                    if (sortRank === "descend") {
                        setSortRank(undefined);
                        setQuery({ ...query, sortBy: undefined });
                    }
                    if (sortRank === undefined) {
                        setSortRank("descend");
                        setQuery({ ...query, sortBy: "rank" });
                        setSortLikeTotal(undefined);
                        setSortView(undefined);
                        setSortCreatedAt(undefined);
                        setSortCode(undefined);
                    }
                },
            }),
        },
        {
            title: <div className="text-title-table">Tỉnh</div>,
            dataIndex: "area",
            key: "area",
            render: (area: any) => {
                return area?.language[0]?.title;
            },
        },
        {
            title: <div className="text-title-table">Thành phố</div>,
            dataIndex: "city",
            key: "city",
            render: (city: any) => {
                return city?.language[0]?.title;
            },
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            sorter: true,
            sortOrder: sortCreatedAt,
            sortDirections: ["descend"] as any,
            onHeaderCell: () => ({
                onClick: () => {
                    if (sortCreatedAt === "descend") {
                        setSortCreatedAt(undefined);
                        setQuery({ ...query, sortBy: undefined });
                    }
                    if (sortCreatedAt === undefined) {
                        setSortCreatedAt("descend");
                        setQuery({ ...query, sortBy: "createdAt" });
                        setSortLikeTotal(undefined);
                        setSortView(undefined);
                        setSortRank(undefined);
                        setSortCode(undefined);
                    }
                },
            }),
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Like</div>,
            key: "likeTotal",
            dataIndex: "likeTotal",
            sorter: true,
            sortOrder: sortLikeTotal,
            sortDirections: ["descend"] as any,
            onHeaderCell: () => ({
                onClick: () => {
                    if (sortLikeTotal === "descend") {
                        setSortLikeTotal(undefined);
                        setQuery({ ...query, sortBy: undefined });
                    }
                    if (sortLikeTotal === undefined) {
                        setSortLikeTotal("descend");
                        setQuery({ ...query, sortBy: "like" });
                        setSortCreatedAt(undefined);
                        setSortView(undefined);
                        setSortRank(undefined);
                        setSortCode(undefined);
                    }
                },
            }),
        },
        {
            title: <div className="text-title-table">View</div>,
            key: "view",
            dataIndex: "view",
            sorter: true,
            sortOrder: sortView,
            sortDirections: ["descend"] as any,
            onHeaderCell: () => ({
                onClick: () => {
                    if (sortView === "descend") {
                        setSortView(undefined);
                        setQuery({ ...query, sortBy: undefined });
                    }
                    if (sortView === undefined) {
                        setSortView("descend");
                        setQuery({ ...query, sortBy: "view" });
                        setSortCreatedAt(undefined);
                        setSortLikeTotal(undefined);
                        setSortRank(undefined);
                        setSortCode(undefined);
                    }
                },
            }),
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            fixed: "right" as any,
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        <button
                            type="button"
                            className="text-gray"
                            onClick={() => handleViewDetail(record.id)}
                        >
                            <i className="bi-eye"></i>
                        </button>
                        <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                window.open(`/admin/school/edit/${record.id}`, "_blank");
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button>
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteRecord(record.id)}
                        >
                            <i className="bi-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDelete(id);
            },
            centered: true,
        });
    };

    const confirmMultiDeleteRecord = () => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: `Bạn có chắc chắn muốn xóa ${selectedRowKeys.length} bản ghi này?`,
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleMultiDelete();
            },
            centered: true,
        });
    };
    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <FilterSchool
                                        data={{...filter, ...query}}
                                        onSearch={(value: any) => setFilter(value)}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn">
                                            <Button
                                                type="primary"
                                                danger
                                                disabled={selectedRowKeys.length === 0}
                                                onClick={confirmMultiDeleteRecord}
                                            >
                                                <i className="bi-trash"></i> Delete
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    window.open("/admin/school/add", "_blank");
                                                }}
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">{/* <FilterSchool /> */}</div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    scroll={{ x: 1500 }}
                                />
                                {/* <UpdateSchool
                                    data={selectedSchool}
                                    onClose={() => setSelectedSchool(null)}
                                /> */}
                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Drawer
                title="Thông tin chi tiết"
                placement={"right"}
                closable={false}
                onClose={() => setOpenDrawer(false)}
                open={openDrawer}
                width={600}
                extra={<CloseOutlined onClick={() => setOpenDrawer(false)} />}
            >
                {detail && !(detail as any).uptime && (
                    <>
                        <Descriptions
                            column={1}
                            title="Thông tin chung"
                            items={[
                                {
                                    key: "name",
                                    label: "Tên trường",
                                    children: detail?.language[0]?.name,
                                },
                                {
                                    key: "linkDetail",
                                    label: "Link chi tiết",
                                    children: <Link to={detail?.linkDetail}>{detail?.linkDetail}</Link>,
                                },
                                {
                                    key: "rank",
                                    label: "Rank",
                                    children: detail?.rank,
                                },
                                {
                                    key: "type",
                                    label: "Loại hình trường",
                                    children: list_school_type?.find((o: any) => o.id === detail?.schoolTypeId)
                                        ?.language[0]?.title,
                                },
                                {
                                    key: "project",
                                    label: "Dự án",
                                    children: detail?.projects?.map((o: any) => o.name).join(", "),
                                },
                                {
                                    key: "specializeds",
                                    label: "Chuyên ngành",
                                    children: detail?.specializeds
                                        ?.map((o: any) => {
                                            return o?.specialized?.language[0]?.title;
                                        })
                                        .join(", "),
                                },
                            ]}
                        />
                        {!detail?.contact ? (
                            <>
                                <Title level={5}>Thông tin liên hệ</Title>
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="Không có thông tin liên hệ"
                                />
                            </>
                        ) : (
                            <>
                                <Descriptions
                                    column={1}
                                    title="Thông tin liên hệ"
                                    items={[
                                        {
                                            key: "address",
                                            label: "Địa chỉ",
                                            children: detail?.contact?.address,
                                        },
                                        {
                                            key: "email",
                                            label: "Email",
                                            children: detail?.contact?.email,
                                        },
                                        {
                                            key: "phone",
                                            label: "Số điện thoại",
                                            children: detail?.contact?.phone,
                                        },
                                        {
                                            key: "website",
                                            label: "Website",
                                            children: (
                                                <Link to={detail?.contact?.website}>{detail?.contact?.website}</Link>
                                            ),
                                        },
                                    ]}
                                />
                                <Descriptions
                                    column={1}
                                    title="Thông tin social"
                                    items={[
                                        {
                                            key: "facebook",
                                            label: "Facebook",
                                            children: (
                                                <Link to={detail?.contact?.socials?.facebook}>
                                                    {detail?.contact?.socials?.facebook}
                                                </Link>
                                            ),
                                        },
                                        {
                                            key: "Youtube",
                                            label: "Youtube",
                                            children: (
                                                <Link to={detail?.contact?.socials?.youtube}>
                                                    {detail?.contact?.socials?.youtube}
                                                </Link>
                                            ),
                                        },
                                        {
                                            key: "Instagram",
                                            label: "Instagram",
                                            children: (
                                                <Link to={detail?.contact?.socials?.instagram}>
                                                    {detail?.contact?.socials?.instagram}
                                                </Link>
                                            ),
                                        },
                                        {
                                            key: "Twitter",
                                            label: "Twitter",
                                            children: (
                                                <Link to={detail?.contact?.socials?.twitter}>
                                                    {detail?.contact?.socials?.twitter}
                                                </Link>
                                            ),
                                        },
                                        {
                                            key: "Linkedin",
                                            label: "Linkedin",
                                            children: (
                                                <Link to={detail?.contact?.socials?.linkedin}>
                                                    {detail?.contact?.socials?.linkedin}
                                                </Link>
                                            ),
                                        },
                                    ]}
                                />
                            </>
                        )}
                        <Descriptions
                            column={1}
                            title="Khu vực"
                            items={[
                                {
                                    key: "area",
                                    label: "Tỉnh",
                                    children: detail?.area?.language[0]?.title,
                                },
                                {
                                    key: "city",
                                    label: "Thành phố",
                                    children: detail?.city?.language[0]?.title,
                                },
                            ]}
                        />
                        <Descriptions
                            column={1}
                            title="Hình ảnh"
                            items={[
                                {
                                    key: "logo",
                                    label: "Ảnh đại diện",
                                    children: detail?.logo ? (
                                        <img
                                            height={200}
                                            src={process.env.REACT_APP_CDN + detail?.logo}
                                            alt="logo"
                                        />
                                    ) : (
                                        <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            description="Không có logo"
                                        />
                                    ),
                                },
                                {
                                    key: "background",
                                    label: "Ảnh nền",
                                    children: detail?.background ? (
                                        <img
                                            height={200}
                                            src={process.env.REACT_APP_CDN + detail?.background}
                                            alt="background"
                                        />
                                    ) : (
                                        <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            description="Không có background"
                                        />
                                    ),
                                },
                            ]}
                        />
                        {!(detail?.medias?.length > 0) ? (
                            <>
                                <Title level={5}>Medias</Title>
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="Không có media"
                                />
                            </>
                        ) : (
                            <Descriptions
                                column={1}
                                title="Medias"
                                items={detail?.medias?.map((o: any) => {
                                    if (o.type === "IMAGE") {
                                        return {
                                            key: o.id,
                                            label: o.type,
                                            children: (
                                                <img
                                                    height={200}
                                                    src={process.env.REACT_APP_CDN + o.url}
                                                    alt="media"
                                                />
                                            ),
                                        };
                                    }
                                    if (o.type === "VIDEO") {
                                        return {
                                            key: o.id,
                                            label: o.type,
                                            children: (
                                                <iframe
                                                    height="200"
                                                    src={o.url}
                                                ></iframe>
                                            ),
                                        };
                                    }
                                    return {
                                        key: o.id,
                                        label: o.type,
                                        children: <Link to={o.url}>{o.url}</Link>,
                                    };
                                })}
                            />
                        )}
                        {!(detail?.scholarships?.length > 0) ? (
                            <>
                                <Title level={5}>Học bổng và hệ đào tạo</Title>
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="Không có thông tin"
                                />
                            </>
                        ) : (
                            <Descriptions
                                column={1}
                                title="Học bổng và hệ đào tạo"
                                items={detail?.scholarships?.map((o: any, index: number) => {
                                    return {
                                        key: o.id,
                                        label: "Học bổng " + (index + 1),
                                        children: (
                                            <Space
                                                direction="vertical"
                                                align="start"
                                            >
                                                <Text>
                                                    <b>Học bổng:</b> {o.scholarship?.language[0]?.title}
                                                </Text>
                                                <Text>
                                                    <b>Loại học bổng:</b> {o.scholarshipType?.language[0]?.title}
                                                </Text>
                                                <Text>
                                                    <b>Hệ đào tạo:</b>{" "}
                                                    {o.trainings
                                                        .map((cur: any) => {
                                                            return cur?.training?.language[0]?.title;
                                                        })
                                                        .join(", ")}
                                                </Text>
                                            </Space>
                                        ),
                                    };
                                })}
                            />
                        )}
                        {!(detail?.attributes?.length > 0) ? (
                            <>
                                <Title level={5}>School Attributes</Title>
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="Không có thông tin"
                                />
                            </>
                        ) : (
                            <Descriptions
                                column={1}
                                title="School Attributes"
                                items={detail?.attributes?.map((o: any, index: number) => {
                                    return {
                                        key: o.id,
                                        label: _.get(SchoolAttributeLabels, o?.name, "") || o?.name,
                                        children: o?.value,
                                    };
                                })}
                            />
                        )}
                    </>
                )}
            </Drawer>
            {contextHolder}
        </div>
    );
};

export default School;
