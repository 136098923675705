import { Comment } from "@ant-design/compatible";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Pagination } from "@components/pagination";
import {
    useCreateApplyDiscussMutation,
    useDeleteApplyMutation,
    useDeleteMultiApplyMutation,
    useGetApplyByIdQuery,
    useGetListApplyPaginateQuery,
    useLazyGetApplyDiscussQuery,
} from "@redux/queries/admin/admin.apply";
import { history } from "@utils/helper-router";
import { Badge, Button, Drawer, Form, Input, List, Modal, Table, Tag, Tooltip, Typography } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { listApplyStatus } from "./apply.enum";
import FilterApply from "./components/FilterApply";
import { listUserRole } from "../users/user.const";
import ApplyDetail from "../apply-process/components/ApplyDetail";

const { Text } = Typography;

const listStatusNews = [
    {
        value: 0,
        label: "INACTIVE",
    },
    {
        value: 1,
        label: "ACTIVE",
    },
];

const Editor = ({ onChange, onSubmit, submitting, value }: any) => (
    <>
        <Form.Item>
            <Input.TextArea
                autoFocus
                rows={4}
                onChange={onChange}
                value={value}
            />
        </Form.Item>
        <Form.Item>
            <Button
                htmlType="submit"
                loading={submitting}
                onClick={onSubmit}
                type="primary"
            >
                Add Comment
            </Button>
        </Form.Item>
    </>
);

const Apply: React.FunctionComponent = () => {
    const [modal, contextHolder] = Modal.useModal();
    const [detailId, setDetailID] = useState<string | null>(null);
    const [openDrawer, setOpenDrawer] = useState(false);

    const [submitting, setSubmitting] = useState(false);
    const [value, setValue] = useState("");

    const [openDrawerDetail, setOpenDrawerDetail] = useState(false);
    const handleViewDetail = (id: string) => {
        setDetailID(id);
        setOpenDrawerDetail(true);
    };

    const handleSubmit = async () => {
        if (!value) return;

        setSubmitting(true);
        const result = await createApplyDiscuss({
            applyId: detailId,
            content: value,
        });
        getApplyDiscuss(detailId);

        setSubmitting(false);
        setValue("");
    };

    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const [filter, setFilter] = useState<any>({});
    const [activeKey, setActiveKey] = useState("titleVi");

    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey);
    };

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    const { data, isLoading, isFetching } = useGetListApplyPaginateQuery(query as any);

    const { data: detail } = useGetApplyByIdQuery({
        id: detailId || "",
    });

    const [getApplyDiscuss, { data: dataDiscuss, isLoading: isLoadingDiscuss }] = useLazyGetApplyDiscussQuery();
    const [createApplyDiscuss] = useCreateApplyDiscussMutation();

    const [deleteApply, isLoadingDelete] = useDeleteApplyMutation();
    const [deleteMultiApply, isLoadingMultiDelete] = useDeleteMultiApplyMutation();

    const handleDelete = async (id: string) => {
        const result = await deleteApply({
            id: id,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const handleViewChat = (record: any) => {
        setDetailID(record.id);
        getApplyDiscuss(record.id);
        setOpenDrawer(true);
    };

    const handleMultiDelete = async () => {
        const result = await deleteMultiApply({
            ids: selectedRowKeys as any,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: <div className="text-title-table">Khách hàng</div>,
            key: "userId",
            dataIndex: "user",
            render: (user: IUser, record: any) => {
                return user ? `${user?.firstName} ${user?.lastName}` : "";
            },
        },
        {
            title: <div className="text-title-table">Trường</div>,
            key: "schoolId",
            dataIndex: "school",
            render: (school: IAdminSchool, record: any) => {
                return school?.language?.[0]?.name || "";
            },
        },
        {
            title: <div className="text-title-table">Ngành học</div>,
            key: "majorId",
            dataIndex: "major",
            render: (major: IMajor, record: any) => {
                return major?.language?.[0]?.title || "";
            },
        },
        {
            title: <div className="text-title-table">Chuyên ngành</div>,
            key: "applySpecializeds",
            dataIndex: "applySpecializeds",
            render: (applySpecializeds: ISpecialized[], record: any) => {
                return applySpecializeds?.map((item) => <Tag key={item.id}>{item.language?.[0]?.title}</Tag>);
            },
        },
        {
            title: <div className="text-title-table">Năm / Giai đoạn</div>,
            dataIndex: "year",
            key: "year",
            render: (year: number, record: any) => {
                return `${year} / ${record.period}`;
            },
        },
        {
            title: <div className="text-title-table">Điểm hồ sơ</div>,
            dataIndex: "user",
            key: "user",
            render: (user: any, record: any) => {
                return user?.score || "";
            },
        },
        {
            title: <div className="text-title-table">Trạng thái</div>,
            dataIndex: "status",
            key: "status",
            render: (status: string) => {
                const statusItem = listApplyStatus.find((item) => item.value === status);
                return <Tag color={statusItem?.color}>{statusItem?.label}</Tag>;
            },
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 150,
            key: "action",
            fixed: "right" as any,
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        <button
                            type="button"
                            className="text-gray"
                            onClick={() => handleViewDetail(record.id)}
                        >
                            <i className="bi-eye"></i>
                        </button>
                        <button
                            type="button"
                            className="text-gray"
                            onClick={() => handleViewChat(record)}
                        >
                            <Badge count={record?.totalDiscuss}>
                                <i className="bi-chat"></i>
                            </Badge>
                        </button>
                        <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                window.open(`/admin/apply/edit/${record.id}`, "_blank");
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button>
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteRecord(record.id)}
                        >
                            <i className="bi-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDelete(id);
            },
            centered: true,
        });
    };

    const confirmMultiDeleteRecord = () => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: `Bạn có chắc chắn muốn xóa ${selectedRowKeys.length} bản ghi này?`,
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleMultiDelete();
            },
            centered: true,
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    };

    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <FilterApply
                                        data={filter}
                                        onSearch={(value: any) => setFilter(value)}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn">
                                            <Button
                                                type="primary"
                                                danger
                                                disabled={selectedRowKeys.length === 0}
                                                onClick={confirmMultiDeleteRecord}
                                            >
                                                <i className="bi-trash"></i> Delete
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    window.open("/admin/apply/add", "_blank");
                                                }}
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">{/* <FilterSchool /> */}</div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    scroll={{ x: 1500 }}
                                />
                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Drawer
                title={`${detail?.user?.firstName} ${detail?.user?.lastName} - ${detail?.school?.language[0]
                    ?.name} - ${(detail as any)?.major?.language[0]?.title}`}
                placement={"right"}
                closable={false}
                onClose={() => setOpenDrawer(false)}
                open={openDrawer}
                width={600}
                extra={<CloseOutlined onClick={() => setOpenDrawer(false)} />}
            >
                <List
                    className="comment-list"
                    itemLayout="horizontal"
                    loading={isLoadingDiscuss}
                    dataSource={dataDiscuss
                        ?.map((item: any) => {
                            const role = listUserRole.find((role) => role.value === item.user?.role);
                            let name = item.user?.username;
                            if (item.user?.firstName && item.user?.lastName) {
                                name = `${item.user?.firstName} ${item.user?.lastName}`;
                            }
                            return {
                                author: (
                                    <>
                                        <Tag color={role?.color}>{role?.label}</Tag>
                                        {name}
                                    </>
                                ),
                                content: <p>{item.content}</p>,
                                datetime: (
                                    <Tooltip title={moment(item.createdAt).format("HH:mm DD/MM/YYYY")}>
                                        <span>{moment(item.createdAt).fromNow()}</span>
                                    </Tooltip>
                                ),
                            };
                        })
                        .reverse()}
                    renderItem={(item: any) => (
                        <li>
                            <Comment
                                author={item.author}
                                content={item.content}
                                datetime={item.datetime}
                            />
                        </li>
                    )}
                    footer={
                        <Comment
                            content={
                                <Editor
                                    onChange={handleChange}
                                    onSubmit={handleSubmit}
                                    submitting={submitting}
                                    value={value}
                                />
                            }
                        />
                    }
                />
            </Drawer>
            <ApplyDetail
                openDrawer={openDrawerDetail}
                setOpenDrawer={(v: boolean) => setOpenDrawerDetail(v)}
                detailId={detailId}
            />
            {contextHolder}
        </div>
    );
};

export default Apply;
