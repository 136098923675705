import blankImage from "@assets/images/blank.png";
import TinyMCE from "@components/Editor";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_DELETE_SUCCESS, TOAST_UPLOAD_ERROR, TOAST_UPLOAD_SUCCESS } from "@consts";
import store from "@redux/store";
import { validateNoSpaces, validateSlug } from "@utils/common";
import { Form, Input, Tabs, UploadFile, message } from "antd";
import Upload, { RcFile, UploadChangeParam } from "antd/es/upload";
import "jodit";
import React, { useEffect, useRef, useState } from "react";

interface IProps {
    introduceVi: string;
    introduceEn: string;
    introduceCn: string;

    setIntroduceVi: (value: string) => void;
    setIntroduceEn: (value: string) => void;
    setIntroduceCn: (value: string) => void;

    thumbnail?: string;
    setThumbnail?: (value: string) => void;
    editThumbnail?: string;

    form: any;
    errorContent?: string;
    setErrorContent?: (value: string) => void;
}

const Tab: React.FunctionComponent<IProps> = (props: IProps) => {
    const {
        introduceVi,
        introduceEn,
        introduceCn,
        setIntroduceVi,
        setIntroduceEn,
        setIntroduceCn,
        form,
        thumbnail,
        setThumbnail,
        editThumbnail,
        errorContent,
        setErrorContent,
    } = props;

    useEffect(() => {
        const thumbnailHtml = document.getElementById("thumbnail") as HTMLImageElement;
        thumbnailHtml.src = "" + process.env.REACT_APP_CDN + editThumbnail;
    }, [editThumbnail]);

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const token = store.getState().auth.accessToken;
    const type = store.getState().auth.type;
    const { showToast } = useToast();

    const initialItems = [] as any[];

    const [activeKey, setActiveKey] = useState("titleVi");
    const [items, setItems] = useState(initialItems);

    useEffect(() => {
        if (errorContent === "titleVi" || errorContent === "titleEn" || errorContent === "titleCn") {
            setActiveKey(errorContent);
        }
        if (errorContent === "introduceVi") {
            setActiveKey("titleVi");
            form.setFields([
                {
                    name: "introduceVi",
                    errors: ["Thông tin bắt buộc"],
                },
            ]);
        }
        if (errorContent === "introduceEn") {
            setActiveKey("titleEn");
            form.setFields([
                {
                    name: "introduceEn",
                    errors: ["Field required"],
                },
            ]);
        }
        if (errorContent === "introduceCn") {
            setActiveKey("titleCn");
            form.setFields([
                {
                    name: "introduceCn",
                    errors: ["必填字段"],
                },
            ]);
        }
        setErrorContent && setErrorContent("");
    }, [errorContent]);

    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey);
    };

    const newTabIndex = useRef(0);

    const add = () => {
        const newActiveKey = `newTab${newTabIndex.current++}`;
        const newPanes = [...items];
        newPanes.push({ label: "New Tab", children: <>Content of new Tab</>, key: newActiveKey, closable: true });
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const remove = (targetKey: any) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        items.forEach((item, i) => {
            if (item.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = items.filter((item) => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: "add" | "remove") => {
        if (action === "add") {
            add();
        } else {
            remove(targetKey);
        }
    };

    const uploadProps: any = {
        name: "files",
        action: `${process.env.REACT_APP_HOST}/api/school/images`,
        method: "POST",
        maxCount: 1,
        accept: "image/*",
        headers: {
            Authorization: `${type} ${token}`,
        },
        fileList,
        showUploadList: false,
        beforeUpload: (file: RcFile) => {
            const isJpgOrPngOrPdf = file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPngOrPdf) {
                message.error("Không đúng định dạng file yêu cầu");
            }
            const thumbnail = document.getElementById("thumbnail") as HTMLImageElement;
            thumbnail.src = URL.createObjectURL(file);
            return isJpgOrPngOrPdf;
        },
        onChange: async (info: UploadChangeParam) => {
            setFileList(info.fileList);
            if (info.file.response && info.file.response && info.file.status === "done") {
                showToast({ ...TOAST_UPLOAD_SUCCESS });
                setFileList([info.file]);
                setThumbnail && setThumbnail(info.file.response && info.file.response[0].url);
            }
            if (info.file.status === "removed") {
                showToast({ ...TOAST_DELETE_SUCCESS });
                setFileList([]);
            }
            if (info.file.status === "error") {
                showToast({ ...TOAST_UPLOAD_ERROR });
            }
        },
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
    ];

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            ["link", "image"],
            ["clean"],
        ],
    };

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Tabs
                    type="editable-card"
                    onChange={onChange}
                    activeKey={activeKey}
                    onEdit={onEdit}
                    items={[
                        {
                            label: "Tiếng Việt",
                            children: (
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="titleVi"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Thông tin bắt buộc",
                                                    },
                                                    {
                                                        validator: (rule, value, callback) =>
                                                            validateNoSpaces(rule, value, callback, "vi"),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Tiêu đề</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="slugVi"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        validator: (rule, value, callback) =>
                                                            validateNoSpaces(rule, value, callback, "vi"),
                                                    },
                                                    {
                                                        validator: (rule, value, callback) => {
                                                            validateSlug(rule, value, callback, "vi");
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Slug</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="introduceVi"
                                                className="form-floating"
                                            >
                                                {/* <ReactQuill
                                                    className="text-editor"
                                                    value={introduceVi}
                                                    onChange={(value: any) => {
                                                        setIntroduceVi(value);
                                                    }}
                                                    formats={formats}
                                                    modules={modules}
                                                /> */}
                                                <TinyMCE
                                                    value={introduceVi}
                                                    onChange={(value: string) => setIntroduceVi(value)}
                                                />
                                            </Form.Item>
                                            <label>Nội dung</label>
                                        </div>
                                    </div>
                                </div>
                            ),
                            key: "titleVi",
                            closable: false,
                        },
                        {
                            label: "English",
                            children: (
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="titleEn"
                                                className="form-floating"
                                                rules={[
                                                    // {
                                                    //     required: true,
                                                    //     message: "Field required",
                                                    // },
                                                    {
                                                        validator: (rule, value, callback) =>
                                                            validateNoSpaces(rule, value, callback, "en"),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Title</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="slugEn"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        validator: (rule, value, callback) =>
                                                            validateNoSpaces(rule, value, callback, "en"),
                                                    },
                                                    {
                                                        validator: (rule, value, callback) => {
                                                            validateSlug(rule, value, callback, "en");
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Slug</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="introduceEn"
                                                className="form-floating"
                                            >
                                                {/* <ReactQuill
                                                    className="text-editor"
                                                    value={introduceEn}
                                                    onChange={(value: any) => {
                                                        setIntroduceEn(value);
                                                    }}
                                                    formats={formats}
                                                    modules={modules}
                                                /> */}
                                                <TinyMCE
                                                    value={introduceEn}
                                                    onChange={(value: string) => setIntroduceEn(value)}
                                                />
                                            </Form.Item>
                                            <label>Content</label>
                                        </div>
                                    </div>
                                </div>
                            ),
                            key: "titleEn",
                            closable: false,
                        },
                        {
                            label: "中国人",
                            children: (
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="titleCn"
                                                className="form-floating"
                                                rules={[
                                                    // {
                                                    //     required: true,
                                                    //     message: "必填字段",
                                                    // },
                                                    {
                                                        validator: (rule, value, callback) =>
                                                            validateNoSpaces(rule, value, callback, "cn"),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>标题</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="slugCn"
                                                className="form-floating"
                                                rules={[
                                                    {
                                                        validator: (rule, value, callback) =>
                                                            validateNoSpaces(rule, value, callback, "cn"),
                                                    },
                                                    {
                                                        validator: (rule, value, callback) => {
                                                            validateSlug(rule, value, callback, "cn");
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Slug</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name="introduceCn"
                                                className="form-floating"
                                            >
                                                {/* <ReactQuill
                                                    className="text-editor"
                                                    value={introduceCn}
                                                    onChange={(value: any) => {
                                                        setIntroduceCn(value);
                                                    }}
                                                    formats={formats}
                                                    modules={modules}
                                                /> */}
                                                <TinyMCE
                                                    value={introduceCn}
                                                    onChange={(value: string) => setIntroduceCn(value)}
                                                />
                                            </Form.Item>
                                            <label>内容</label>
                                        </div>
                                    </div>
                                </div>
                            ),
                            key: "titleCn",
                            closable: false,
                        },
                    ]}
                />

                <div className="body-component">
                    <div className="form-group">
                        <div className="frame-upload">
                            <img
                                className="img-fluid"
                                src={blankImage}
                                alt=""
                                id="thumbnail"
                            />
                        </div>
                        <Upload.Dragger {...uploadProps}>
                            <div className="upload-btn-wrapper">
                                <button className="btn">Tải ảnh thumbnail</button>
                            </div>
                        </Upload.Dragger>
                    </div>
                </div>
            </div>
        </>
    );
};

const MemoizedTab = React.memo(Tab);

export default MemoizedTab;
