import {
    useDeleteBlogCategoryMutation,
    useDeleteMultiBlogCategoryMutation,
    useGetBlogCategoryPaginateQuery,
} from "@redux/queries/user/categories.blog";
import { Button, Modal, Table } from "antd";
import React, { useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { Pagination } from "@components/pagination";
import HandleForm from "./components/HandleForm";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_DELETE_ERROR, TOAST_DELETE_SUCCESS } from "@consts";

const CategoryBlog = () => {
    const [modal, contextHolder] = Modal.useModal();
    const [openHandleForm, setOpenHandleForm] = useState(false);
    const [dataEdit, setDataEdit] = useState<any>(null);
    const [query, setQuery] = useState({
        page: 1,
        limit: 10,
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const { showToast } = useToast();

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const [deleteBlogCategory] = useDeleteBlogCategoryMutation();
    const [deleteMultiBlogCategory] = useDeleteMultiBlogCategoryMutation();
    const { data, isFetching, isLoading } = useGetBlogCategoryPaginateQuery(query as any);

    const handleDelete = async (id: string) => {
        const result = await deleteBlogCategory({
            id: id,
        });

        if ("error" in result) {
            showToast({ ...TOAST_DELETE_ERROR });
        }

        if ("data" in result) {
            showToast({ ...TOAST_DELETE_SUCCESS });
        }
    };

    const handleMultiDelete = async () => {
        const result = await deleteMultiBlogCategory({
            ids: selectedRowKeys as any,
        });

        if ("error" in result) {
            showToast({ ...TOAST_DELETE_ERROR });
        }

        if ("data" in result) {
            showToast({ ...TOAST_DELETE_SUCCESS });
        }
    };

    const columns = [
        {
            title: <div className="text-title-table">Tên tiếng Việt</div>,
            dataIndex: "language",
            key: "titleVn",
            render: (language: any) => {
                const lang = language.find((item: any) => item.lang === "vi");
                return lang?.title;
            },
        },
        {
            title: <div className="text-title-table">Slug tiếng Việt</div>,
            dataIndex: "language",
            key: "slugVi",
            render: (language: any) => {
                const lang = language.find((item: any) => item.lang === "vi");
                return lang?.slug;
            },
        },
        {
            title: <div className="text-title-table">Tên tiếng Anh</div>,
            dataIndex: "language",
            key: "titleEn",
            render: (language: any) => {
                const lang = language.find((item: any) => item.lang === "en");
                return lang?.title;
            },
        },
        {
            title: <div className="text-title-table">Slug tiếng Anh</div>,
            dataIndex: "language",
            key: "slugEn",
            render: (language: any) => {
                const lang = language.find((item: any) => item.lang === "en");
                return lang?.slug;
            },
        },
        {
            title: <div className="text-title-table">Tên tiếng Trung</div>,
            dataIndex: "language",
            key: "titleCn",
            render: (language: any) => {
                const lang = language.find((item: any) => item.lang === "cn");
                return lang?.title;
            },
        },
        {
            title: <div className="text-title-table">Slug tiếng Trung</div>,
            dataIndex: "language",
            key: "slugCn",
            render: (language: any) => {
                const lang = language.find((item: any) => item.lang === "cn");
                return lang?.slug;
            },
        },
        {
            title: <div className="text-title-table">Ngày tạo</div>,
            key: "createdAt",
            dataIndex: "createdAt",
            render: (createdAt: any) => {
                return moment(createdAt).format("DD/MM/YYYY");
            },
        },
        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            fixed: "right" as any,
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                setDataEdit(record);
                                setOpenHandleForm(true);
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button>
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteRecord(record.id)}
                        >
                            <i className="bi-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDelete(id);
            },
            centered: true,
        });
    };

    const confirmMultiDeleteRecord = () => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: `Bạn có chắc chắn muốn xóa ${selectedRowKeys.length} bản ghi này?`,
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleMultiDelete();
            },
            centered: true,
        });
    };

    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <div className="group-btn">
                                        <Button
                                            type="primary"
                                            danger
                                            disabled={selectedRowKeys.length === 0}
                                            onClick={confirmMultiDeleteRecord}
                                        >
                                            <i className="bi-trash"></i> Delete
                                        </Button>
                                        <Button
                                            type="primary"
                                            onClick={() => setOpenHandleForm(true)}
                                        >
                                            Add New
                                        </Button>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">{/* <FilterSchool /> */}</div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    scroll={{ x: 1500 }}
                                />

                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <HandleForm
                    openHandleForm={openHandleForm}
                    setOpenHandleForm={(v: boolean) => setOpenHandleForm(v)}
                    dataEdit={dataEdit}
                    setDataEdit={(d: any) => setDataEdit(d)}
                />
            </main>
            {contextHolder}
        </div>
    );
};

export default CategoryBlog;
