import Img01 from "@assets/images/img-01.jpg";
import NumberFormat from "@components/NumberFormat";
import { linkCDN } from "@utils/common";
import React from "react";
import renderHtml from "react-render-html";
import { Link } from "react-router-dom";

interface ShortPostProps {
    id: string;
    category: string;
    title: string;
    avatar: string;
    username: string;
    time: string;
    content: string;
    like: number;
    comment: number;
    save: number;
    thumbnail: string;
    slug?: string;
}

const ShortPost: React.FunctionComponent<ShortPostProps> = (props) => {
    return (
        <>
            <div className="card card-article">
                <div className="row">
                    <div className="col-12 col-sm-5">
                        <figure className="snap">
                            <img
                                className="img-fluid"
                                src={linkCDN(props.thumbnail, Img01)}
                            />
                            <figcaption>
                                <h5>Xem chi tiết</h5>
                            </figcaption>
                            <Link
                                to={`https://studyinchina.io/tin-tuc/${props.slug}`}
                                title="Xem chi tiết"
                                target="_blank"
                            />
                        </figure>
                    </div>
                    <div className="col-12 col-sm-7">
                        <div className="card-body">
                            <h6>
                                <span>{props.category}</span>
                            </h6>
                            <h5>
                                <a href="#">{props.title}</a>
                            </h5>
                            <ul>
                                <li>
                                    <i className="bi-person"></i> {props.username}
                                </li>
                                <li>
                                    <i className="bi-hand-thumbs-up"></i>{" "}
                                    <NumberFormat
                                        value={props.like}
                                        suffix=" Hits"
                                    />
                                </li>
                                {/* <li>
                                    <i className="bi-chat-dots"></i> Mark Jecno
                                </li> */}
                            </ul>
                            <p className="render-content-html">{renderHtml(props.content)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShortPost;
