import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useGetListMajorQuery } from "@redux/queries/admin/admin.majors";
import { useCreateMajorMutation, useUpdateMajorMutation } from "@redux/queries/admin/admin.majors";
import { Col, Form, Input, Modal, Row, Select } from "antd";
import { useEffect, useState } from "react";
import Tab from "./Tab";
import { pickBy, identity } from "lodash";
import { useGetListTrainingQuery } from "@redux/queries/admin/admin.training";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createMajors, isLoadingCreate] = useCreateMajorMutation();
    const [updateMajors, isLoadingUpdate] = useUpdateMajorMutation();
    const { showToast } = useToast();
    const { data: list_major, isLoading: isLoadingMajor, error: errorMajor } = useGetListMajorQuery({
        parent: '1'
    });
    const { data: list_train, isLoading: isLoadingTrain, error: error } = useGetListTrainingQuery();

    const [thumbnail, setThumbnail] = useState<any>(null);
    const [editThumbnail, setEditThumbnail] = useState<any>(null);

    const [introduceVi, setIntroduceVi] = useState<any>("");
    const [introduceEn, setIntroduceEn] = useState<any>("");
    const [introduceCn, setIntroduceCn] = useState<any>("");
    const [errorContent, setErrorContent] = useState<string>();

    useEffect(() => {
        if (dataEdit) {
            const introduceVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.content;
            const introduceEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.content;
            const introduceCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.content;
            const shortContentVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.shortContent;
            const shortContentEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.shortContent;
            const shortContentCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.shortContent;
            setIntroduceVi(introduceVi || "");
            setIntroduceEn(introduceEn || "");
            setIntroduceCn(introduceCn || "");
            form.setFieldsValue({
                ...dataEdit,
                titleVi: dataEdit?.language[0].title,
                titleEn: dataEdit?.language[1].title,
                titleCn: dataEdit?.language[2].title,
                slugVi: dataEdit?.language[0].slug,
                slugEn: dataEdit?.language[1].slug,
                slugCn: dataEdit?.language[2].slug,
                shortContentVi,
                shortContentEn,
                shortContentCn,
                majorId: dataEdit?.major?.id,
                introduceVi,
                introduceEn,
                introduceCn,
                trainingId: dataEdit?.training?.id,
            });
            setEditThumbnail(dataEdit?.thumbnail);
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        let value;
        try {
            value = await form.validateFields();
            if (!introduceVi) {
                setErrorContent("introduceVi");
                return;
            }
            if (!introduceEn) {
                setErrorContent("introduceEn");
                return;
            }
            if (!introduceCn) {
                setErrorContent("introduceCn");
                return;
            }
        } catch (errorInfo) {
            const fieldError = (errorInfo as any)?.errorFields[0].name[0];
            if (fieldError === "titleVi" || fieldError === "titleEn" || fieldError === "titleCn") {
                setErrorContent(fieldError);
            }
            return;
        }
        const language = [
            {
                lang: "vi",
                title: values.titleVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.title,
                content: values.introduceVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.content,
                shortContent:
                    values.shortContentVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.shortContent,
                slug: values.slugVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.slug,
            },
            {
                lang: "en",
                title: values.titleEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.title,
                content: values.introduceEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.content,
                shortContent:
                    values.shortContentEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.shortContent,
                slug: values.slugEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.slug,
            },
            {
                lang: "cn",
                title: values.titleCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.title,
                content: values.introduceCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.content,
                shortContent:
                    values.shortContentCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.shortContent,
                slug: values.slugCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.slug,
            },
        ];

        const data = pickBy(
            {
                ...values,
                language,
                thumbnail: thumbnail || editThumbnail,
            },
            identity
        ) as any;
        const result = dataEdit
            ? await updateMajors({
                  id: dataEdit.id,
                  ...data,
              })
            : await createMajors(data);
        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }
        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setEditThumbnail(null);
            setThumbnail(null);
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update Majors" : "Add Majors"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
                width={1000}
            >
                <div className="body-component">
                    <div className="form-group">
                        <div className="form-floating">
                            <Tab
                                form={form}
                                thumbnail={thumbnail}
                                setThumbnail={(value: any) => setThumbnail(value)}
                                introduceVi={introduceVi}
                                introduceEn={introduceEn}
                                introduceCn={introduceCn}
                                setIntroduceVi={(value: any) => setIntroduceVi(value)}
                                setIntroduceEn={(value: any) => setIntroduceEn(value)}
                                setIntroduceCn={(value: any) => setIntroduceCn(value)}
                                errorContent={errorContent}
                                setErrorContent={(value: any) => setErrorContent(value)}
                            />
                        </div>
                    </div>
                    <Row gutter={20}>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="code"
                                        className="form-floating"
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Code</label>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="parentId"
                                        className="form-floating"
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                        >
                                            {list_major &&
                                                list_major.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.id}
                                                            value={o.id}
                                                        >
                                                            {o.language[0].title}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                    <label>Chọn ngành học cha</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            {" "}
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="trainingId"
                                        className="form-floating"
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                        >
                                            {list_train &&
                                                list_train.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.id}
                                                            value={o.id}
                                                        >
                                                            {o.language[0].title}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                    <label>Hệ đào tạo</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

            </Modal>
        </Form>
    );
};

export default HandleForm;
