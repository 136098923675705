import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import {
    useCreateSchoolNewsMutation,
    useGetSchoolNewsByIdQuery,
    useUpdateSchoolNewsMutation,
} from "@redux/queries/admin/admin.school.news";
import { history } from "@utils/helper-router";
import { Button, Card, Form, message, Row, Space } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GeneralTab from "../components/GeneralTab";
import dayjs from "dayjs";
import moment from "moment";

export interface FormSchoolValues {
    title_vi?: string;
    title_en?: string;
    title_cn?: string;
    linkDetail?: string;
    rank?: number;
    code: string;
    projectIds?: string;
    schoolTypeId?: string;
}

const Create: React.FunctionComponent = () => {
    const [createSchoolNews, result] = useCreateSchoolNewsMutation();
    const [updateSchoolNews, resultUpdate] = useUpdateSchoolNewsMutation();
    const { showToast } = useToast();
    const location = useLocation();
    const id = location.pathname.split("/")[4];

    const { data: dataEdit, isLoading } = useGetSchoolNewsByIdQuery({
        id,
    });

    const [editThumbnail, setEditThumbnail] = useState<any>(null);

    useEffect(() => {
        if (dataEdit) {
            const titleVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.title;
            const titleEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.title;
            const titleCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.title;
            const introduceVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.content;
            const introduceEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.content;
            const introduceCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.content;
            const slugVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.slug;
            const slugEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.slug;
            const slugCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.slug;
            const shortContentVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.shortContent;
            const shortContentEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.shortContent;
            const shortContentCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.shortContent;
            const thumbnail = dataEdit?.thumbnail;
            const tags = dataEdit?.tags;
            const status = dataEdit?.status;
            const categoryId = dataEdit?.category?.id;
            const schoolId = dataEdit?.school?.id;
            const schoolNewsScholarships = dataEdit?.schoolNewsScholarships?.map((item: any) => {
                const data = JSON.parse(item);
                return {
                    ...data,
                    expireApply: dayjs.unix(data.expireApply),
                };
            });
            setEditThumbnail(thumbnail);
            setIntroduceVi(introduceVi || "");
            setIntroduceEn(introduceEn || "");
            setIntroduceCn(introduceCn || "");
            form.setFieldsValue({
                titleVi,
                titleEn,
                titleCn,
                introduceVi,
                introduceEn,
                introduceCn,
                slugVi,
                slugEn,
                slugCn,
                thumbnail,
                tags,
                status,
                categoryId,
                schoolId,
                schoolNewsScholarships,
                shortContentVi,
                shortContentEn,
                shortContentCn,
            });
        }
    }, [dataEdit]);

    const [form] = Form.useForm();

    const [thumbnail, setThumbnail] = useState<any>(null);

    const [introduceVi, setIntroduceVi] = useState<any>("");
    const [introduceEn, setIntroduceEn] = useState<any>("");
    const [introduceCn, setIntroduceCn] = useState<any>("");
    const [errorContent, setErrorContent] = useState<string>();

    useEffect(() => {
        if (errorContent) {
            message.error("Vui lòng nhập đủ thông tin");
        }
    }, [errorContent]);

    const handleSubmit = async (isOpenNewTab = false) => {
        let value;
        try {
            value = await form.validateFields();
            if (!introduceVi) {
                setErrorContent("introduceVi");
                return;
            }
            if (!introduceEn) {
                setErrorContent("introduceEn");
                return;
            }
            if (!introduceCn) {
                setErrorContent("introduceCn");
                return;
            }
        } catch (errorInfo) {
            const fieldError = (errorInfo as any)?.errorFields[0].name[0];
            if (fieldError === "titleVi" || fieldError === "titleEn" || fieldError === "titleCn") {
                setErrorContent(fieldError);
            }
            return;
        }
        const language = [
            {
                lang: "vi",
                title: value.titleVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.title,
                shortContent:
                    value.shortContentVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.shortContent,
                content: introduceVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.content,
                slug: value.slugVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.slug,
            },
            {
                lang: "en",
                title: value.titleEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.title,
                shortContent:
                    value.shortContentEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.shortContent,
                content: introduceEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.content,
                slug: value.slugEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.slug,
            },
            {
                lang: "cn",
                title: value.titleCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.title,
                shortContent:
                    value.shortContentCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.shortContent,
                content: introduceCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.content,
                slug: value.slugCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.slug,
            },
        ];
 
        const mapSchoolNewsScholarships = value.schoolNewsScholarships.map((item: any) => {
            console.log(item.monthApply);
            return {
                monthApply: item.monthApply,
                year: Number(item.year),
                expireApply: moment(item.expireApply).unix(),
                feeApply: Number(item.feeApply),
                trainingId: item.trainingId,
                majorSpecializationIds: item.majorSpecializationIds,
                trainingLanguage: item.trainingLanguage,
            };
        });
  
        const data = _.pickBy(
            {
                language,
                thumbnail: thumbnail || dataEdit?.thumbnail,
                tags: value.tags.filter((item: any) => item),
                status: value.status,
                categoryId: value.categoryId,
                schoolId: value.schoolId,
                schoolNewsScholarships: mapSchoolNewsScholarships
            },
            (item) => item !== undefined && item !== null && item !== ""
        );
        const result = id
            ? await updateSchoolNews({
                  ...data,
                  id,
              } as any)
            : await createSchoolNews(data as any);
        if ("error" in result) {
            id ? showToast({ ...TOAST_UPDATE_ERROR }) : showToast({ ...TOAST_CREATE_ERROR });
        }

        if ("data" in result) {
            id ? showToast({ ...TOAST_UPDATE_SUCCESS }) : showToast({ ...TOAST_CREATE_SUCCESS });
            !isOpenNewTab && history.navigate("/admin/school-news");
        }
    };

    const handleSubmitAndOpenNewTab = async () => {
        await handleSubmit(true);
        const newTabUrl = `/news/${id}`;
        window.open(newTabUrl, "_blank");
    };

    return (
        <>
            <div className="container-fluid padding0">
                <span className="screen-darken"></span>
                <main>
                    <div>
                        <section id="content-main">
                            <Form
                                form={form}
                                scrollToFirstError
                                initialValues={dataEdit}
                            >
                                <div className="box-component">
                                    <div>
                                        <Card
                                            title="Thêm mới tin tức"
                                            bordered={false}
                                            style={{ width: "100%" }}
                                            loading={isLoading}
                                        >
                                            <GeneralTab
                                                form={form}
                                                introduceVi={introduceVi}
                                                introduceEn={introduceEn}
                                                introduceCn={introduceCn}
                                                setIntroduceVi={(value: any) => setIntroduceVi(value)}
                                                setIntroduceEn={(value: any) => setIntroduceEn(value)}
                                                setIntroduceCn={(value: any) => setIntroduceCn(value)}
                                                thumbnail={thumbnail}
                                                setThumbnail={(value: any) => setThumbnail(value)}
                                                editThumbnail={editThumbnail}
                                                errorContent={errorContent}
                                                setErrorContent={(value: any) => setErrorContent(value)}
                                                dataEdit={dataEdit}
                                            />
                                        </Card>
                                    </div>
                                    <Row
                                        justify="center"
                                        gutter={2}
                                    >
                                        <Space>
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    onClick={handleSubmitAndOpenNewTab}
                                                >
                                                    Submit and open new tab
                                                </Button>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    onClick={() => handleSubmit()}
                                                >
                                                    Submit
                                                </Button>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button
                                                    onClick={() => {
                                                        history.navigate("/admin/school-news");
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Form.Item>
                                        </Space>
                                    </Row>
                                </div>
                            </Form>
                        </section>
                    </div>
                </main>
            </div>
        </>
    );
};

export default Create;
