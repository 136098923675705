import { schoolBg } from "@consts";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

interface SpecializedItemProps {
    data: ISpecialized;
}

const SpecializedItem: React.FunctionComponent<SpecializedItemProps> = ({ data }) => {
    const { id, language, thumbnail, level, rating, rank, major, training } = data || {};
    const MAX_STAR = 5;
    const light: any = (Number(rating) ?? MAX_STAR) > MAX_STAR ? MAX_STAR : Number(rating);
    const normal = MAX_STAR - light;

    const navigate = useNavigate();

    const goToDetail = () => {
        navigate(`/specialized/${id}`);
    };

    const colors: Record<string, string> = {
        ["Bachelor"]: "text-box-green",
        ["Master"]: "text-box-blue",
        ["Doctor"]: "text-box-red",
    };

    return (
        <div className="col-12 col-sm-6 col-xl-4 col-xxl-3 d-flex">
            <div className="box-filter-item w-100">
                <Link to={`/specialized/${id}`}>
                    <div
                        onClick={goToDetail}
                        className="bg-item"
                        style={{
                            background: `url("${
                                thumbnail ? `${process.env.REACT_APP_CDN}${thumbnail}` : schoolBg
                            }") center center / cover no-repeat`,
                        }}
                    >
                        <div className="d-flex bg-content-sub">
                            <ul>
                                {light > 0 &&
                                    Array(light)
                                        .fill("star")
                                        .map((e, id) => (
                                            <li
                                                key={id}
                                                className="active"
                                            >
                                                <i className="bi-star-fill" />
                                            </li>
                                        ))}
                                {normal > 0 &&
                                    Array(normal)
                                        .fill("normal")
                                        .map((e, id) => (
                                            <li key={id}>
                                                <i className="bi-star-fill star-normal" />
                                            </li>
                                        ))}
                            </ul>
                            <p className="text-p">{`#${rank}`}</p>
                        </div>
                    </div>

                    <div className="body-filter-item">
                        <h6
                            className="m-3 mb-2"
                            onClick={goToDetail}
                        >
                            {language?.[0]?.title}
                        </h6>
                        <div className="ms-3 mb-2 fw-bold">
                            <span className={`${colors[training?.language?.[1]?.title]}`}>
                                {training?.language?.[0]?.title}
                            </span>
                        </div>
                        <ul>
                            <li>Tiếng Trung: {language?.[2]?.title}</li>
                            <li>Tiếng Anh: {language?.[1]?.title}</li>
                            <li>Chuyên môn: {major?.language?.[0]?.title}</li>
                            <li>Cấp độ: {level}</li>
                        </ul>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default SpecializedItem;
